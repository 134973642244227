// const ServerUrl = 'http://localhost/storemgt/';
const ServerUrl = 'https://store1.adigrp.com/storemgt/';

/*

User Name



Password



*/


export default ServerUrl;