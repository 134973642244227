import { useLocation } from "react-router-dom";
import Dashboard from "../../Dashboard";
import { useEffect, useState } from "react";
import axios from "axios";
import ServerUrl from "../../ServerUrl";

const SerDCUpdate = () => { 
    const location = useLocation();
    function convertToFiveDigitZero(number) {
        // Convert the number to a string and pad with zeros
        return String(number).padStart(5, '0');
    }
    const sno = location.state.sno;
    const [GetDcDetails, SetDcDetails] = useState();
    useEffect(()=>  {
        const formdata = new FormData();
        formdata.append("sno", sno);
        axios.post(ServerUrl+"public/serapi/GetIndDcMfg.php", formdata, {withCredentials:true}).then(response => { 
            SetDcDetails(response.data);
        }).catch(err => { 
            console.log(err);
        })
    },[])
    
    const dcdetails = GetDcDetails ? GetDcDetails[0].dcdetails : 0;
    const dcproducts = GetDcDetails ? GetDcDetails[0].dcproducts : 0;
    console.log(dcdetails);
    console.log(dcproducts);

// Update Product

// Main Logical Quantity of Products To Out
const [GetStackProductQuant, SetStackProductQuant] = useState([]);
useEffect(()=>{
  axios
      .get(ServerUrl + "public/serapi/StockAvailable.php", {
        withCredentials: true,
      })
      .then((res) => {
        SetStackProductQuant(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
},[]);
// console.log(GetStackProductQuant)
const productStackArray = GetStackProductQuant.filter((item) => {
  return parseInt(item.FinalStock) !== 0 && parseInt(item.FinalStock) > 0
});

const productStackOptions = productStackArray.map(item => { 
  
  return { value: item.ProductID, label: item.Name , value2:item.FinalStock};
})

// Update Product


    const UpdateDeliveryChallan = (sno, quantity, oldquant, max) => {
        
        if(parseInt(quantity) === 0 || parseInt(max) < parseInt(quantity)){
            alert("Default Value and Zero Values Can't Update or Your going Out of Stock");
        }else{
                if(parseInt(oldquant) === parseInt(quantity)){
                    alert("Old Quantity and New Quantity is Same");
                }else{
                    const formdata = new FormData();
                    formdata.append("sno",sno);
                    formdata.append("quantity",quantity);

                    axios.post(ServerUrl+"public/serapi/updatedcn.php", formdata, { withCredentials: true }).then(response => {
                        alert(response.data);
                        window.location.reload();
                    }).catch(err => { 
                        console.log(err)
                    })
                }
        }
    }

    const UpdateDeliveryChallanReturnable = (sno, quantity, oldquant, max) => {
        
        if(parseInt(quantity) === 0 || parseInt(max) < parseInt(quantity)){
            alert("Default Value and Zero Values Can't Update Your going Out of Stock");
        }else{
                if(parseInt(oldquant) === parseInt(quantity)){
                    alert("Old Quantity and New Quantity is Same");
                }else{
                    const formdata = new FormData();
                    formdata.append("sno",sno);
                    formdata.append("quantity",quantity);
                    axios.post(ServerUrl+"public/serapi/updatedcr.php", formdata, {withCredentials:true}).then(response => {
                        alert(response.data);
                        window.location.reload();
                    }).catch(err => {
                        console.log(err);
                    })
                }
        }
    }

    return <>
    <Dashboard />
    <div className="container">
    <h5>Update APEPL/DC/{convertToFiveDigitZero(location.state.sno)}</h5>
    <hr/>
    <label>Client Name : {dcdetails.clientname} &nbsp;&nbsp;&nbsp; DC Type : {dcdetails.dctype === "1" ? "Returnable" : "Non-Returnable" }</label>
    <hr/>
    <table className="table table-dark">
        <thead>
            <tr>
                <th>Product Name</th>
                <th>Product Quantity</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {dcproducts ? dcproducts.map(data =>{
                let BlankObj = { quantity : 0 };
                const productdetails = productStackOptions.filter(data1 => { return parseInt(data1.value) === parseInt(data.productid) })
                console.log(productdetails)
                return <tr>
                <td>{data.productname}</td>
                <td>{dcdetails.dctype === "1"  ? data.returnstatus === "1" ?  data.quantity : <input type="number" className="form form-control" onChange={(e) => { BlankObj.quantity = parseInt(e.target.value) }} min={0} max={productdetails ? productdetails[0] ? parseInt(productdetails[0].value2)+parseInt(data.quantity) : 0 : 0} defaultValue={data.quantity} /> : <input type="number" className="form form-control" onChange={(e) => { BlankObj.quantity = parseInt(e.target.value) }} min={0} max={productdetails ? productdetails[0] ? parseInt(productdetails[0].value2)+parseInt(data.quantity) : 0 : 0} defaultValue={data.quantity} />}</td>
                <td>{dcdetails.dctype === "1"  ? data.returnstatus === "1" ?  "Product Returned "+data.rquantity : <button className="btn btn-success" onClick={()=> {UpdateDeliveryChallanReturnable(data.sno, BlankObj.quantity, data.quantity, productdetails ? productdetails[0] ? parseInt(productdetails[0].value2)+parseInt(data.quantity) : 0 : 0) }}>Update</button> : <button className="btn btn-success" onClick={()=> {UpdateDeliveryChallan(data.sno, BlankObj.quantity, data.quantity, productdetails ? productdetails[0] ? parseInt(productdetails[0].value2)+parseInt(data.quantity) : 0 : 0) }}>Update</button>}</td>
            </tr>
            }) : null}
            
        </tbody>
    </table>
    </div>
    </>
}

export default SerDCUpdate;