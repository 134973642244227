import Dashboard from "../Dashboard";
import DCR from "./DCMFG/DCR";

const DCReturnPro = () => {
    
    return <>
    <Dashboard />
    {
            localStorage.getItem("urole") === "2" || localStorage.getItem("urole") === "1" ? <DCR />: <></>
        }
    </>;
}

export default DCReturnPro;