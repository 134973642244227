import axios from "axios";
import { useRef } from "react";
import ServerUrl from "../ServerUrl";

const ProductUpdate = (props) => { 
    const { sno, name, desc, hsncode } = props;
    const newNameRef = useRef();
    const newDescRef = useRef();
    const newHsnCodeRef = useRef();


    const HandleFormUpdate = (e) => {
        e.preventDefault();
        if(Boolean(newNameRef.current.value) && Boolean(newDescRef.current.value) && Boolean(newHsnCodeRef.current.value)){
            const formdata = new FormData();
            formdata.append("sno", sno);
            formdata.append("productname", newNameRef.current.value);
            formdata.append("productdesc", newDescRef.current.value);
            formdata.append("producthsn", newHsnCodeRef.current.value);

            axios.post(ServerUrl+"public/products/updatePro.php", formdata, { withCredentials:true }).then(response => { 
                alert(response.data);
                window.location.reload();
            }).catch(err => {
                console.log(err)
            })
        }else{
            alert("Some Fields Are blank");
        }
    }
    return <>
        <form onSubmit={HandleFormUpdate}>
            <label>Product Name</label>
            <input type="text"  ref={newNameRef} className="form form-control" defaultValue={name} />
            <label>Product Description</label>
            <input type="text" ref={newDescRef} className="form form-control" defaultValue={desc} />
            <label>Product HSN Code</label>
            <input type="text" ref={newHsnCodeRef} className="form form-control" defaultValue={hsncode} />
            <br/>
            <button type="submit" className="btn btn-primary">Update</button>

        </form>
    </>
}

export default ProductUpdate;