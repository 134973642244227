import axios from "axios";
import { useEffect, useState } from "react";
import ServerUrl from "../../ServerUrl";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const DCMFG = () => {

  

  const [userdata, setuserdata] = useState();
    
  useEffect(() => {
    axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
    //  console.log(res)
      if(res.data.flag === true){
        //   window.location.href = "/dashboard";
        setuserdata(res.data.data);
        
      }else if(res.data.flag === false){
        window.location.href = "/";
      }
    }
    ).catch((err)=>{
      console.log(err);
    });

  }, []);




    const [vendorList, SetVendorList] = useState([]);
    useEffect(()=>{
        axios.get(ServerUrl+"public/RemoteDataAccess/VendorsList.php", {withCredentials:true}).then(response => { 
          SetVendorList(response.data);
            console.log(response.data)
        }).catch(err => { 
            console.log(err);
        })
    },[])

    const [clientList, SetClientList] = useState([]);
    useEffect(()=>{
        axios.get(ServerUrl+"public/RemoteDataAccess/ClientList.php", {withCredentials:true}).then(response => { 
            SetClientList(response.data);
            console.log(response.data)
        }).catch(err => { 
            console.log(err);
        })
    },[])


    const ClientListForSelect = vendorList.map(data =>  {
        return {label : data.acompanyname, value : data.aadress+" "+data.acountry, value2 : data.acontactperson, value3: data.sno, value4 : data.agstinno}
    })

    const DCType = [{label: "Returnable", value:1}, { label: "Non- Returnable", value : 0 }];


    // Product List Goes Here
    const [productsList, setProductsList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/getproductslist.php", {
        withCredentials: true,
      })
      .then((res) => {
        setProductsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const productsoptions = productsList.map((item) => {
    return { value: item.ProductID, label: item.Name };
  });

// Main Logical Quantity of Products To Out
const [GetStackProductQuant, SetStackProductQuant] = useState([]);
useEffect(()=>{
  axios
      .get(ServerUrl + "public/products/MfgStackApi/StockAvailable.php", {
        withCredentials: true,
      })
      .then((res) => {
        SetStackProductQuant(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
},[]);
// console.log(GetStackProductQuant)
const productStackArray = GetStackProductQuant.filter((item) => {
  return parseInt(item.FinalStock) !== 0 && parseInt(item.FinalStock) > 0
});

const productStackOptions = productStackArray.map(item => { 
  return { value: item.ProductID, label: item.Name , value2:item.FinalStock};
})



// Units List
const [unitsList, setUnitsList] = useState([]);
useEffect(() => {
  axios
    .get(ServerUrl + "public/products/productin/getunits.php", {
      withCredentials: true,
    })
    .then((res) => {
      setUnitsList(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
}, []);
const unitsoptions = unitsList ? unitsList.map((item) => {
  return { value: item.UnitID, label: item.Abbreviation };
}) : null;


// const [blankArray, newUpArray] = useState([]);


// const CompSamp = (props) =>   <tr>
// <td><Select options={productsoptions} placeholder="Product" /> </td>
// <td>    <input type="text"  className="form form-control" placeholder="Enter Quanity Here" /></td>
// <td><Select options={unitsoptions} placeholder="Select Unit" /></td>

// </tr>;

// Variable To GetData

const [selclientDetails, selsetClientDetails] = useState();
const [dcType, setDcType] = useState();

 // State to hold the array of rows
 const [rows, setRows] = useState([]);

 // Function to add a new row to the array
 const addRow = () => {
   setRows([...rows, { product: '', quantity: '', unit: '', pkg:'' }]);
 };

 // Function to remove a row from the array
 const removeRow = (index) => {
   const updatedRows = [...rows];
   updatedRows.splice(index, 1);
   setRows(updatedRows);
 };

 // Function to handle changes in Select and input components
 const handleInputChange = (index, key, value) => {
   const updatedRows = [...rows];
   updatedRows[index][key] = value;
   setRows(updatedRows);
 };

 function isArrayOfObjectsEmpty(array) {
  // Check if the array is defined and has a length greater than zero
  return Array.isArray(array) && array.length === 0;
}



const HandleSubmitForm = (e) => { 
  e.preventDefault();
  const formdata = new FormData();
  if (isArrayOfObjectsEmpty(rows)) {
    alert("Please Insert Product to Generate Delievery Challan")
  }
  else{
     /*
    INITIALS
    $clientName = $_POST['clientName'];
    $clientId = $_POST['clientId'];
    $dcType = $_POST['dcType']; 
    $userid = $_POST['userid'];
    $clientaddress = $_POST['clientaddress'];
    $clientgst = $_POST['clientgst'];
    $contactPersonname = $_POST['contactpersonname'];
  */
  
  formdata.append("clientName",selclientDetails.label);
  formdata.append("clientId",selclientDetails.value3);
  formdata.append("dcType",dcType.value);
  formdata.append("userid",userdata ? userdata.id : null);
  formdata.append("clientaddress",selclientDetails.value);
  formdata.append("clientgst",selclientDetails.value4);
  formdata.append("contactpersonname",selclientDetails.value2);
  formdata.append("productslist", JSON.stringify(rows));
  axios.post(ServerUrl+"public/dc/CreateDcMfg.php", formdata,{ withCredentials : true }).then(response => {
    alert(response.data)
    window.location.reload();
  }).catch(err => { console.log(err) })

  }



}


const [getDCList, setDCList] = useState();
useEffect(()=>{
  axios.get(ServerUrl+"public/dc/GetDcMfg.php", { withCredentials:true}).then(response => { 
    setDCList(response.data)
  }).catch(err => { 
    console.log(err);
  })
},[])

const navigate = useNavigate();





    return <div className="container">
      
      <form onSubmit={HandleSubmitForm}>
        <h5>Generate Delivery Challan Manufacturing</h5>
        <label>Select Vendor</label>
        <Select options={ClientListForSelect} onChange={(newValue) => { 
          selsetClientDetails(newValue)
        }} placeholder="Select Client" maxMenuHeight={45} required />
        <label>Select DC Type</label>
        <Select options={DCType} placeholder="Select DC Type" onChange={(newValue) => { 
          setDcType(newValue)
        }}  required />
        <label>Select Product Below</label>
        <hr/>
        <table className="table table-light">
          <thead>
            <tr>
              <td>Products</td>
              <td>Quanity</td>
              <td>Unit</td>
              <td>Pkg/ Wt</td>
              <td>Action</td>
            </tr>
            </thead>
        <tbody>

       
        {rows ? rows.map((row, index) => {
          console.log(row.product.value2);
            return <tr key={index}>
              <td>
                <Select
                  options={productStackOptions}
                  placeholder="Product"
                  value={row.product}
                  onChange={(value) => handleInputChange(index, 'product', value) }
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form form-control"
                  placeholder="Enter Quantity Here"
                  value={row.quantity}
                  max={row.product.value2}
                  min={0}
                  onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                  required
                />
              </td>
              <td>
                <Select
                  options={unitsoptions}
                  placeholder="Select Unit"
                  value={row.unit}
                  onChange={(value) => handleInputChange(index, 'unit', value)}
                  required
                />
              </td>
              <td>
              <input
                  type="text"
                  className="form form-control"
                  placeholder="Enter Pkg/Wt Here"
                  value={row.pkg}
                  onChange={(e) => handleInputChange(index, 'pkg', e.target.value)}
                  required
                />
              </td>
              <td>
                <button onClick={() => removeRow(index)} className="btn btn-danger">-</button>
              </td>
            </tr>
}) : <></>}
      



        </tbody>
        </table>
        <button onClick={addRow} className="btn btn-success">+</button>

        <hr/>
        <button className="btn btn-primary">Submit</button>
        
        </form>


<hr/>
<h3>DC List</h3>

<table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">DCNO</th>
      <th scope="col">Client Name</th>
      <th scope="col">Contact Person</th>
      <th scope="col">Generate Date</th>
      <th scope="col">Action</th>

    </tr>
  </thead>
  <tbody>
    {getDCList ? 
      getDCList.map(data => { 
        return <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.clientname}</td>
        <td>{data.contactpersonname}</td>
        <td>{data.createdate}</td>
        <td>
            <button className="btn btn-success" onClick={()=> { navigate('mfg', { state : { sno : data.sno }, replace:false }) }}>🖨</button> &nbsp;
            <button className="btn btn-primary" onClick={() => { navigate('update', {state : { sno : data.sno }, replace :false}) }}>🗏</button>
        </td>
      </tr>
      }).reverse()
    :<></>}
    
    
  </tbody>
</table>


    </div>
}

export default DCMFG;