import axios from "axios";
import Dashboard from "../Dashboard";
import { useState, useEffect } from "react";
import ServerUrl from "../ServerUrl";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
const Settings = () => { 
    
        const [userdata, setuserdata] = useState();
        const navigate = useNavigate();
      useEffect(() => {
        axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
        //  console.log(res)
          if(res.data.flag === true){
            //   window.location.href = "/dashboard";
            setuserdata(res.data.data);
            if(res.data.data.role !== "1"){
               navigate("/");
               axios.get(ServerUrl+"public/deletehttptokencoockie.php", {withCredentials:true}).then(res=> { 
                window.location.reload();
                }).catch(err => { 
                    console.log(err);
                });
            }
            
          }else if(res.data.flag === false){
            window.location.href = "/";
          }
        }
        ).catch((err)=>{
          console.log(err);
        });
    
      }, []);
      const roleoptions = [{label:"Manufacturing User", value : 2},{label:"Service User", value:3}];

    const [getUserName, setUserName] = useState();
    const [getEmail, setEmail] = useState();
    const [getPassWord, setPassWord] = useState();
    const [getUserRole, setUserRole] = useState();

      const HandleFormSubmitCreateUser = (e) => {
        e.preventDefault();
        if(Boolean(getUserName) && Boolean(getEmail) && Boolean(getPassWord) && Boolean(getUserRole)){
            const formdata = new FormData();
            formdata.append("username", getUserName);
            formdata.append("uemail", getEmail);
            formdata.append("upassword", getPassWord);
            formdata.append("urole", getUserRole);
            axios.post(ServerUrl+"public/userapi/createuser.php", formdata, {withCredentials:true}).then(response => {
                if(response.data.flag === true){
                    alert(response.data.message);
                    window.location.reload();
                }else{
                    alert(response.data.message);
                }
            }).catch(err =>{
                console.log(err)
            })

       }else{
        alert("All Fields Required");
       } 
      }

      const [getUserDataList, setUserDataList] = useState();
      const GetUserDataListData = () => {
        axios.get(ServerUrl+"public/userapi/userlistgetter.php", {withCredentials: true}).then(response => { 
            setUserDataList(response.data);
        }).catch(err => {
            console.log(err);
        })
      }
      useEffect(GetUserDataListData, [])

    return (
        <>
            <Dashboard />
            <div className="container">
                <hr/>
                <form onSubmit={HandleFormSubmitCreateUser}>
                <h6>Create User</h6>
                <label className="form form-label">Full Name</label>
                <input type="text" className="form form-control" onChange={(e) => { setUserName(e.target.value); }} placeholder="Enter A Full Name" />
                <label className="form form-label">Email Id</label>
                <input type="email" onChange={(e) => { setEmail(e.target.value); }} className="form form-control" placeholder="Enter Email Id" />
                <label className="form form-label">Password</label>
                <input type="text" onChange={(e) => { setPassWord(e.target.value) }} className="form form-control" placeholder="Enter Passowrd Id" />
                <label className="form form-label">Select Role</label>
                <Select options={roleoptions} onChange={(newValue) => { setUserRole(newValue.value) }} placeholder="Select User Role" />
                <hr/>
                <button className="btn btn-primary" type="submit">Create User</button>
                </form>
                
                <hr/>
                <table className="table table-dark">
                    <thead>
                        <tr>
                            <td>User ID</td>
                            <td>Full Name</td>
                            <td>Email Id</td>
                            <td>Role</td>
                            <td>Create Date</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {getUserDataList ? getUserDataList.map(data => {
                            let objtemp = {sno: data.sno, pass:null}
                            return <tr>
                                <td>{data.sno}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.role === "2" ? "Manufacturing" : "Service"}</td>
                                <td>{data.createdate}</td>
                                <td><input type="text" className="form form-control" onChange={(e) => { objtemp.pass=e.target.value }} placeholder="Enter New Password" />
                                <button className="btn btn-success" onClick={() => { 
                                    if(Boolean(objtemp.pass)){
                                        const formdata = new FormData();
                                        formdata.append("sno", data.sno);
                                        formdata.append("password", objtemp.pass);
                                            axios.post(ServerUrl+"public/userapi/updatepassword.php", formdata, {withCredentials:true}).then(response => {
                                                alert(response.data);
                                            }).catch(err => { 
                                                console.log(err);
                                            })
                                    }else{
                                        alert("Please Fill Password");
                                    }
                                }}>Change Password</button></td>
                             </tr>
                        }) : null}
                       
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Settings;