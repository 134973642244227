import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Products from './Components/Products';
import DeliveryChallan from './Components/DelieveryChallan';
import Settings from './Components/Settings';
import ToPdfViewer from './Components/DCMFG/DCPdf';
import DCReturnPro from './Components/DCReturnPro';
import DCUpdate from './Components/DCMFG/DCUpdate';
import SProduct from './Components/SProduct';
import ToPdfViewerSer from './Components/DCSer/SERDCPdf';
import SerDCUpdate from './Components/DCSer/SerDCUpdate';
import SERDCR from './Components/DCSer/SERDCR';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App/>} />
      <Route path='/dashboard' element={<Home />} />
      <Route path='/products' element={<Products />} />
      
      <Route path='/dc' element={<DeliveryChallan />} />
      <Route path='/dc/update' element={<DCUpdate />} />
      <Route path='/dc/serupdate' element={<SerDCUpdate />} />


      <Route path='/dc/mfg' element={<ToPdfViewer />} />
      <Route path='/dc/ser' element={<ToPdfViewerSer />} />

      <Route path='/dcr' element={<DCReturnPro />} />
      <Route path='/serdcr' element={<SERDCR />} />

      
      <Route path='/settings' element={<Settings />} />

  {/* Service Products */}
  <Route path='/sproducts' element={<SProduct />} />

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
