import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import ServerUrl from "../../ServerUrl";

const DCR = () => {

    const [GetDCList, SetDCList] = useState([]);
    useEffect(() => { 
        axios.get(ServerUrl+"public/dc/GetDcMfg.php", {withCredentials:true}).then(response => { 
            SetDCList(response.data);
        }).catch(err => { 
            console.log(err)
        })
    }, [])


    function convertToFiveDigitZero(number) {
        // Convert the number to a string and pad with zeros
        return String(number).padStart(5, '0');
      }

    const SelectArrayRow = GetDCList ? GetDCList.filter(data => {return data.dctype === "1"}) : []
    const SelectOption = SelectArrayRow.map(data => { return { label :"APEPL/DC/"+convertToFiveDigitZero(data.sno), value : data.sno } })

    //   Variable For Select Value
    const [GetSelectedOption, SetSelectedOption] = useState();
      
    //   Get DC Details
    const [GetDCStack, SetDCStack] = useState();
    const DCstackFunction = () => { 
        const formdata = new FormData();
        formdata.append("dcid", GetSelectedOption);
        axios.post(ServerUrl+"public/dc/GetReturnableDCStock.php",  formdata,{withCredentials:true}).then(response => {
            SetDCStack(response.data);
        }).catch(err => { 
            console.log(err);
        })
    }
    useEffect(DCstackFunction, [GetSelectedOption])

    console.log(GetDCStack);






    return <>
    <div className="container">
        <h5>Log Return Products</h5>
            <Select options={SelectOption} onChange={(newValue) => { 
                SetSelectedOption(newValue.value)
            }} />

            <hr />



<table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">OBJECT ID</th>
      <th scope="col">PRODUCT NAME</th>
      <th scope="col">Quanity</th>
      <th scope="col">Return Quantity</th>
      <th scope="col">Note</th>

      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {GetDCStack ? GetDCStack.map(data => { 
        const HandleStockReturnSubmit = (e) => { 
            e.preventDefault();
            if(Boolean(e.target[0].value)){
                console.log(e.target[0].value)
                const formdata = new FormData();
                formdata.append("sno", data.sno);
                formdata.append("rquantity", parseInt(data.rquantity)+parseInt(e.target[0].value));
                formdata.append("dcid", GetSelectedOption);
                formdata.append("productid", data.productid);
                formdata.append("productname", data.productname)
                formdata.append("quantity", parseInt(e.target[0].value));
                formdata.append("unit", data.unit);
                formdata.append("pkg", data.pkg);
                axios.post(ServerUrl+"public/dc/insertreturnstock.php", formdata, { withCredentials:true }).then(response => { 
                    DCstackFunction();
                    alert(response.data);
                }).catch(err =>  {
                    console.log(err);
                })
            }else{
                console.log("Please Fill All Fields");
            }
        }

        return <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.productname}</td>
        <td>{data.quantity}</td>
        <td>{data.rquantity}</td>
        <td>Stock Pending {parseInt(data.quantity)-parseInt(data.rquantity)}</td>


        <td>{parseInt(data.quantity)-parseInt(data.rquantity) ? <form onSubmit={HandleStockReturnSubmit}><input type="number" name="ss" max={parseInt(data.quantity)-parseInt(data.rquantity)} min={0} placeholder="Enter Returned Stock"  className="form form-control" required /> <button className="btn btn-success" type="submit" >IN</button></form> : <>Received</>}</td>
      </tr>
    }) : null}
    
  </tbody>
</table>


    </div>
    </>
}
export default DCR;