import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import ServerUrl from "../ServerUrl";

const ProductIn = () => {
  const [vendorsList, setVendorList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/RemoteDataAccess/VendorsList.php", {
        withCredentials: true,
      })
      .then((res) => {
        setVendorList(res.data);
        // console.log(vendorsList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const vendorsoptions = vendorsList.map((item) => {
    return { value: item.sno, label: item.acompanyname };
  });

  const [productsList, setProductsList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/getproductslistverf.php", {
        withCredentials: true,
      })
      .then((res) => {
        setProductsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const productsoptions = productsList.map((item) => {
    return { value: item.ProductID, label: item.Name+' '+item.Description };
  });

  // Units List
  const [unitsList, setUnitsList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/productin/getunits.php", {
        withCredentials: true,
      })
      .then((res) => {
        setUnitsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const unitsoptions = unitsList ? unitsList.map((item) => {
    return { value: item.UnitID, label: item.Abbreviation };
  }) : null;

  const [statuslist, setStatuslist] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/productin/getstatus.php", {
        withCredentials: true,
      })
      .then((res) => {
        setStatuslist(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const statusoptions = statuslist.map((item) => {
    return { value: item.StatusID, label: item.StatusDesc };
  });



  // List for assetstype
    const [assetstypeList, setAssetstypeList] = useState([]);
    useEffect(() => {
        axios.get(ServerUrl+"public/products/productin/getassetstype.php", {withCredentials:true}).then(res => {
            setAssetstypeList(res.data);
        }
        ).catch(err => { 
            console.log(err);
        })
    },[])
    const assetstypeoptions = assetstypeList.map((item) => {
        return { value: item.AssetID, label: item.AssetDesc };
    });

// Ref Variable For All Fields
const vendordetails = useRef();
const productdetails = useRef();
const quantity = useRef();
const unit = useRef();
const status = useRef();
const assettype = useRef();
const comment = useRef();






const HandleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    // console.log(vendordetails.current.props.value);
    // console.log(productdetails.current.props.value);
    // console.log(unit.current.props.value);
    // console.log(status.current.props.value);
    // console.log(assettype.current.props.value);




    if(Boolean(vendordetails.current.props.value) && Boolean(productdetails.current.props.value) && Boolean(quantity.current.value) && Boolean(unit.current.props.value) && Boolean(status.current.props.value) && Boolean(assettype.current.props.value)){
            /*
                   $vendorname = $_POST['vendorname'];
                    $vendorid = $_POST['vendorid'];
                    $productname = $_POST['productname'];
                    $productid = $_POST['productid'];
                    $productquantity = $_POST['productquantity'];
                    $productunitid = $_POST['productunitid'];
                    $productstatusid = $_POST['productstatusid'];
                    $productassetstypeid = $_POST['productassetstypeid'];
                    $comment = $_POST['comment'];
                    above fields names are mentioned in API now i will create formdata
            */

                    
                const formdata = new FormData();
                formdata.append('vendorname', vendordetails.current.props.value.label);
                formdata.append('vendorid', vendordetails.current.props.value.value);
                formdata.append('productname', productdetails.current.props.value.label);
                formdata.append('productid', productdetails.current.props.value.value);
                formdata.append('productquantity', quantity.current.value);
                formdata.append('productunitid', unit.current.props.value.value);
                formdata.append('productstatusid', status.current.props.value.value);
                formdata.append('productassetstypeid', assettype.current.props.value.value);
                formdata.append('comment', comment.current.value);
                axios.post(ServerUrl+"public/products/productin/addproductin.php", formdata, {withCredentials:true}).then(res => {
                    if(res.data.flag === true){
                        alert("Product In Created Successfully");
                        comment.current.value = "";
                        quantity.current.value = "";
                        vendordetails.current.clearValue();
                        productdetails.current.clearValue();
                        unit.current.clearValue();
                        status.current.clearValue();
                        assettype.current.clearValue();
                        window.location.reload()

                    }else{
                        alert("Something Went Wrong While Creating Product In");
                    }
                } ).catch(err => { 
                    console.log(err);
                })

                
    }
    else{
        alert("Please Enter All Details");
    }
}

const [productinlist, setproductinlist] = useState([]);
useEffect(()=> {  
        axios.get(ServerUrl+"public/products/productin/getproductsinlist.php", {withCredentials:true}).then(response => { 
            setproductinlist(response.data);
        }).catch(err => { 
          console.log(err)
        })
}, [])


console.log(productinlist);

const [searchvariable, setSearchvariable] = useState("");
const ArrayForFIltered = productinlist.filter((product) => {
  // I want to Search with All Fields
  return product.ProductName.toLowerCase().includes(searchvariable.toLowerCase()) || product.Quantity.toLowerCase().includes(searchvariable.toLowerCase()) || product.VendorName.toLowerCase().includes(searchvariable.toLowerCase()) || product.CreatedDate.toLowerCase().includes(searchvariable.toLowerCase());
}
)
  return (
    <>
      <h1>Product Store In</h1>
      <hr />
      <div className="container">
<form onSubmit={HandleFormSubmit}>
        <label>Select Vendor Name</label>
        <Select options={vendorsoptions} ref={vendordetails} placeholder="select Vendor Name" />
        <label>Select Product To Store In</label>
        <Select options={productsoptions} ref={productdetails} placeholder="select Product Name" />

        <br />
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <label> Enter Quantity</label>
              <input
                type="text"
                placeholder="Enter Quantity"
                className="form form-control"
                onChange={(e) => { isNaN(e.target.value) ? e.target.style.color="red" :e.target.style.color="black"} }
                ref={quantity}
              />
            </div>

            <div className="col-sm">
              <label> Select Unit</label>
              <Select options={unitsoptions} ref={unit} placeholder="select Unit" />
            </div>
          </div>
        </div>
        <br />
        <label>Product Status</label>
        <Select options={statusoptions} ref={status} placeholder="select Status" />
        <br />
        <label>Product Asset Type</label>
        <Select options={assetstypeoptions} ref={assettype} placeholder="select Asset Type" />
        <br />
        <label>Any Comment</label>
        <textarea className="form form-control" placeholder="Enter Comment" ref={comment} />
        <button type="submit" className="btn btn-primary">Submit</button> &nbsp;
        <button type="reset" className="btn btn-danger">Clear</button>
        </form>
<br/>
<hr/>
{/* Search In table */}
<input type="text" className="form form-control" onChange={(e) => {
     setSearchvariable(e.target.value);
 }} placeholder="Search In Products" />
<div className="table-container">
    <table className="table table-dark">
      <thead>
          <tr>
            <th>Sr. No</th>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Vendor Name</th>
            <th>Date Time</th>
          </tr>
      </thead>
      <tbody>
            {ArrayForFIltered ? ArrayForFIltered.map((data) =>{
              return <tr>
                <td>{data.SNo}</td>
                <td>{data.ProductName}</td>
                <td>{data.Quantity}</td>
                <td>{data.VendorName}</td>
                <td>{data.CreatedDate}</td>
              </tr>
            }  ).reverse(): <></>}
      </tbody>
    </table>
</div>

      </div>
    </>
  );
};
export default ProductIn;
