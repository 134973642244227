import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import ServerUrl from "../ServerUrl";

const JobNo = () => {
  const [jobnolist, setjobnolist] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/JobNo/getjobnolist.php", {
        withCredentials: true,
      })
      .then((res) => {
        setjobnolist(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(jobnolist);

  const jobname = useRef();
  const jobmodel = useRef();
  const capacity = useRef();
  const stbnonstb = useRef();
  const plcorpid = useRef();

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (
      Boolean(jobname.current.value) &&
      Boolean(jobmodel.current.value) &&
      Boolean(capacity.current.value) &&
      Boolean(stbnonstb.current.props.value) &&
      Boolean(plcorpid.current.props.value)
    ) {
      /*
    $jobname = $_POST['jobname'];
    $model = $_POST['model'];
    $capacity  = $_POST['capacity'];
    $stb= $_POST['stb'];
    $plc = $_POST['plc'];
                for above field create formdata object to send it to Backend API
            */
      const formdata = new FormData();
      formdata.append("jobname", jobname.current.value);
      formdata.append("model", jobmodel.current.value);
      formdata.append("capacity", capacity.current.value);
      formdata.append("stb", stbnonstb.current.props.value.value);
      formdata.append("plc", plcorpid.current.props.value.value);
      axios
        .post(ServerUrl + "public/products/JobNo/createjobno.php", formdata, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.flag === true) {
            alert(response.data.message);
            jobname.current.value = "";
            jobmodel.current.value = "";
            capacity.current.value = "";
            stbnonstb.current.clearValue();
            plcorpid.current.clearValue();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please Enter All Details");
    }
  };

  return (
    <div>
      <h1>Job Number Creation</h1>
      <form onSubmit={HandleSubmit}>
        <label>Enter Job Name</label>
        <input
          type="text"
          ref={jobname}
          className="form form-control"
          placeholder="Enter Job Name"
        />
        <label>Enter Job Model</label>
        <input
          type="text"
          ref={jobmodel}
          className="form form-control"
          placeholder="Enter Job Model"
        />
        <label>Enter Capcity</label>
        <input
          type="text"
          ref={capacity}
          className="form form-control"
          placeholder="Enter Capcity"
        />
        <label>Select STB On Non STB</label>
        <Select
          placeholder="Select STB On Non STB"
          ref={stbnonstb}
          options={[
            { label: "Stand By", value: 1 },
            { label: "Non Stand By", value: 0 },
          ]}
        />
        <label>Select PLC or PID</label>
        <Select
          placeholder="PLC or PID"
          ref={plcorpid}
          options={[
            { label: "PLC", value: "PLC" },
            { label: "PID", value: "PID" },
          ]}
        />
        <br />
        <button className="btn btn-primary" type="submit">
          Submit
        </button>
        &nbsp;
        <button className="btn btn-danger" type="reset">
          Clear
        </button>
      </form>

      <hr />

      {/* Table For Job No View */}
      <div className="table-container">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Job NO</th>
              <th>Job Name</th>
              <th>Job Model</th>
              <th>Capacity</th>
              <th>STB / Non STB</th>
              <th>PLC / PID</th>
              <th>Create Date</th>
            </tr>
          </thead>

          <tbody>
            {jobnolist
              ? jobnolist.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.JobNo}</td>
                      <td>{item.Name}</td>
                      <td>{item.Model}</td>
                      <td>{item.Capacity}</td>
                      <td>{item.STB ? "Stand By" : "Non Stand By"}</td>
                      <td>{item.PLC}</td>
                      <td>{item.CreateDate}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobNo;
