import Dashboard from "../Dashboard";
import DCMFG from "./DCMFG/DC";
import DCSER from "./DCSER";

const DeliveryChallan = () => { 
    console.log(localStorage.getItem("urole"));
    return (
       <>
       <Dashboard />
       {localStorage.getItem("urole") === "1" ? <><DCMFG /> <hr/> <DCSER /> </>  : <>{localStorage.getItem("urole") === "2" ? <DCMFG /> : <DCSER />}</>};
       
        


       </>
    )
}

export default DeliveryChallan;