import { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import axios from "axios";
import ServerUrl from "../ServerUrl";
    
const SProduct = () => { 

        const [getUnClaimPro, setUnClaimPro] = useState();
        const ClaimFunction = ()=>{
            axios.get(ServerUrl+"public/serapi/getUnClaimPro.php", {withCredentials:true}).then(response => {
                setUnClaimPro(response.data)
            }).catch(err => {
                console.log(err);
            })
        }
        useEffect(ClaimFunction,[])
        console.log(getUnClaimPro)

        const [getClaimProList, setClaimProList] = useState();
        const ClaimProlIstFunction = ()=>{
            axios.get(ServerUrl+"public/serapi/getClaimPro.php", {withCredentials:true}).then(response => { 
                setClaimProList(response.data);
            }).catch(err => { 
                console.log(err)
            })
        }
        useEffect(ClaimProlIstFunction, [])
        console.log(getClaimProList)
        return<>
            <Dashboard />
          <div className="container">
          <table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">Sr No</th>
      <th scope="col">Product Name</th>
      <th scope="col">Product Quantity</th>
      <th scope="col">Date</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {getUnClaimPro ? getUnClaimPro.map(data => {
        return <tr>
        <th scope="row">{data.SNo}</th>
        <td>{data.ProductName}</td>
        <td>{data.Quantity}</td>
        <td>{data.CreateDate}</td>
        <td><button className="btn btn-primary" onClick={()=>{
            const formdata = new FormData();
            formdata.append("OutID",data.SNo)
            formdata.append("ProductName",data.ProductName)
            formdata.append("ProductID",data.ProductID)
            formdata.append("ClientName",data.ClientName)
            formdata.append("ClientID",data.ClientID)
            formdata.append("Quantity",data.Quantity)
            formdata.append("UnitID",data.UnitID)
            axios.post(ServerUrl+"public/serapi/claimpro.php", formdata, {withCredentials:true}).then(response => {
                alert(response.data);
                ClaimFunction();
                ClaimProlIstFunction();
            }).catch(err => {
                console.log(err)
            })

          
        }}>Claim</button></td>
      </tr>
    })  : null}
    
    
  </tbody>
</table>

<hr/>

<h6>Products In Stock</h6>
<table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">SNo</th>
      <th scope="col">Product Name</th>
      <th scope="col">Quantity</th>
   
    </tr>
  </thead>
  <tbody>
{getClaimProList ? getClaimProList.map(data => {
    return <tr>
    <th scope="row">{data.SNo}</th>
    <td>{data.ProductName}</td>
    <td>{data.Quantity}</td>
  </tr>
}) : null}
    

  </tbody>
</table>


          </div>

        </>
    }

    export default SProduct;