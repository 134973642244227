import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ServerUrl from "../ServerUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductUpdate from "./ProductUpdate";
const ProDef = () => {
    const [userdata, setuserdata] = useState([]);
    const notify = (sno, name, desc, hsncode) => toast(<ProductUpdate sno={sno} name={name} desc={desc} hsncode={hsncode} />);
    useEffect(() => {
        axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
        //  console.log(res)
          if(res.data.flag === true){
            //   window.location.href = "/dashboard";
            setuserdata(res.data.data);
          }else if(res.data.flag === false){
            window.location.href = "/";
          }
        }
        ).catch((err)=>{
          console.log(err);
        });
      }, []);
    const [productsList, setProductsList] = useState([]);
    const ProFetchingFunction = () => {
        axios.get(ServerUrl+"public/products/getproductslist.php", {withCredentials:true}).then(res => {
                setProductsList(res.data); 
        }
        ).catch(err => { 
            console.log(err);
        })
    }
    useEffect(()=>{
        ProFetchingFunction()
    },[])
    // console.log(productsList);
    const productname = useRef();
    const productdescription = useRef();
    const producthsn = useRef();
    const [searchvariable, setSearchvariable] = useState("");
    const ArrayForFIltered = productsList.filter((product) => {
        // I want to Search with All Fields
        return product.Name.toLowerCase().includes(searchvariable.toLowerCase()) || product.Description.toLowerCase().includes(searchvariable.toLowerCase()) || product.HSN.toLowerCase().includes(searchvariable.toLowerCase()) || product.CreateDate.toLowerCase().includes(searchvariable.toLowerCase());
    }
    )
    const HandleSubmitForm = (e) => { 
        e.preventDefault();
        if(Boolean(productname.current.value) && Boolean(productdescription.current.value) && Boolean(producthsn.current.value)){
            const formdata = new FormData();
            formdata.append('productname', productname.current.value);
            formdata.append('productdescription', productdescription.current.value);
            formdata.append('producthsn', producthsn.current.value);
            axios.post(ServerUrl+"public/products/addproduct.php", formdata, {withCredentials:true}).then(res => {
                if(res.data.flag === true){
                    alert("Product Defination Created Successfully");
                    productdescription.current.value = "";
                    producthsn.current.value = "";
                    productname.current.value = "";
                }else{
                    alert("Something Went Wrong While Creating Product");
                }
            } ).catch(err => { 
                console.log(err);
            })
        }
        else{
            alert("Please Enter All Details");
        }
    }
    return <>
    <h1>Products</h1>
    <form onSubmit={HandleSubmitForm}>
    <label>Enter Product Name</label>
    <input type="text" ref={productname} className="form form-control"  placeholder="Enter Product Name"/>
    <label>Enter Product Description</label>
    <input type="text" className="form form-control" ref={productdescription} placeholder="Enter Product Description" />
    <label>Enter Product HSN</label>
    <input type="text" className="form form-control" ref={producthsn} placeholder="Enter Product HSN" />
<br/>
    <button className="btn btn-primary" type="submit">Submit</button>
&nbsp;
    <button className="btn btn-danger" type="reset">Clear</button>
    </form>
    <hr/>
{/* Search In table */}
<input type="text" className="form form-control" onChange={(e) => {
     setSearchvariable(e.target.value);
 }} placeholder="Search In Products" />



{/* Boostrap Table */}
<div className="table-container">
<table className="table table-bordered table-hover">

<thead>
    <tr>
        <th>Product ID</th>
        <th>Product Name</th>
        <th>Product Description</th>
        <th>Product HSN</th>
        <th>Date</th>
        {userdata.role === "1" ? <th>Approved</th> : null}
        <th>Action</th>
    </tr>
</thead>

<tbody>

{ArrayForFIltered ? ArrayForFIltered.map((product, index) => {
    return <tr key={index}>
        <td>{product.ProductID}</td>
        <td>{product.Name}</td>
        <td>{product.Description}</td>
        <td>{product.HSN}</td>
        <td>{product.CreateDate}</td>
        {userdata.role === "1" ? <td><button onClick={() => { 
            const formdata = new FormData();
            formdata.append("productid", product.ProductID);
            formdata.append("approvestate", product.approved === "1" ? 0 : 1);
            axios.post(ServerUrl+"public/products/updateAppr.php", formdata,{withCredentials : true}).then(response => { 
                console.log(response.data);
                // window.location.reload();
                ProFetchingFunction()
            }).catch(err => { 
                console.log(err);
            })
        }} className={product.approved === "1" ? "btn btn-primary" : "btn btn-danger"}>{product.approved === "1" ? "Approved" : "Pending"}</button></td> : null}
        <td><button onClick={()=>{ notify(product.ProductID, product.Name, product.Description, product.HSN) }} className="btn btn-primary">Update</button></td>
    </tr>
}).reverse() : null


}


</tbody>

</table>
<ToastContainer
position="top-center"
autoClose={false}
newestOnTop
rtl={false}
pauseOnFocusLoss
draggable
theme="light"
/>
</div>
    </>;
}
export default ProDef;