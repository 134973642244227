import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ServerUrl from "../../ServerUrl";
import html2pdf from 'html2pdf.js';
import logo from "./logo512.png";
import stamp from "../stamp.png";
const ToPdfViewerSer = () => { 






    const location = useLocation();
    // alert(location.state.sno)
    const [userdata, setuserdata] = useState();
    
    useEffect(() => {
      axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
      //  console.log(res)
        if(res.data.flag === true){
          //   window.location.href = "/dashboard";
          setuserdata(res.data.data);
          
        }else if(res.data.flag === false){
          window.location.href = "/";
        }
      }
      ).catch((err)=>{
        console.log(err);
      });
  
    }, []);
    const [getDcData, setData] = useState();
    useEffect(()=>{
        const formdata = new FormData()
        formdata.append("sno", location.state.sno ? location.state.sno : null);
        axios.post(ServerUrl+"public/serapi/GetIndDcMfg.php",formdata, {withCredentials:true}).then(response => { 
            setData(response.data)
        }).catch(err => { 
            console.log(err)
        })
    
    }, [location.state.sno])
console.log(getDcData)
    const [getChallanProducts, setChallanProducts] = useState();
    useEffect(()=>{

    }, [location.state.sno])

    let dcdetails = getDcData ? getDcData[0].dcdetails : null;
    let dcproducts = getDcData ? getDcData[0].dcproducts : null;


    function convertToFiveDigitZero(number) {
        // Convert the number to a string and pad with zeros
        return String(number).padStart(5, '0');
      }
      let a = 1;
      let allquantity = 0;
      let pktwt = 0;
      const [GetproductsSnoDescription, SetproductsSnoDescription] = useState([]);
      useEffect(()=>{
        axios.get(ServerUrl+"public/products/getproductslist.php", {withCredentials:true}).then(response => { 
          SetproductsSnoDescription(response.data);
        })
      }, [])

      const [GetUnitList, SetUnitList] = useState([]);
      useEffect(()=>{
        axios.get(ServerUrl+"public/products/productin/getunits.php", {withCredentials:true}).then(response => { 
          SetUnitList(response.data);
        })
      }, []);

   
      
      const pdfContentRef = useRef(null);

      const generatePDF = () => {
        const input = pdfContentRef.current;
    
        if (input) {
          // Configuration for html2pdf
          const options = {
            margin: [2, 2, 2, 2],
            filename: "APEPL/DC/"+convertToFiveDigitZero(dcdetails ? dcdetails.sno : 0)+".pdf",
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          };
    
          html2pdf().from(input).set(options).save();
        }
      }



    return<>

 <div>
 <div ref={pdfContentRef}>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:0px 5px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}\n.tg .tg-f4iu{border-color:inherit;font-size:12px;text-align:center;vertical-align:top}\n.tg .tg-73a0{border-color:inherit;font-size:12px;text-align:left;vertical-align:top}\n.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}\n.tg .tg-0lax{text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  {/* TD Padding Changed */}
  <div className="tg-wrap">
    <table className="tg" style={{ tableLayout: "fixed", width: 700 }}>
      {/* 813 */}
      <colgroup>
        <col style={{ width: "90.88889px" }} />
        <col style={{ width: "126.88889px" }} />
        <col style={{ width: "90.88889px" }} />
        <col style={{ width: "89.88889px" }} />
        <col style={{ width: "100.88889px" }} />
        <col style={{ width: "108.88889px" }} />
        <col style={{ width: "121.88889px" }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-f4iu">
            <img
              src={logo}
              alt="Ad Pharma Equipment Logo"
              width={40}
              height={40}
            />
          </td>
          <td className="tg-f4iu" colSpan={6}>
            <h4 style={{ fontWeight: "bold" }}>DELIVERY CHALLAN</h4>
            {/* <br /> */}
            {/* <br /> */}
            {dcdetails ? dcdetails.dctype === "1" ? "Returnable" : "Non-Returnable" : null}
          </td>
        </tr>
        <tr>
          <td className="tg-73a0" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>DC.No. </span>APEPL/DC/{convertToFiveDigitZero(dcdetails ? dcdetails.sno : 0)}
          </td>
          <td className="tg-73a0" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>Date:</span> {dcdetails ? dcdetails.createdate : null}
          </td>
          <td className="tg-73a0" colSpan={2}>
            <span style={{ fontWeight: "bold" }}>P.O. No.</span>
          </td>
          <td className="tg-73a0">
            <span style={{ fontWeight: "bold" }}>Date :</span>
          </td>
        </tr>
        <tr>
          <td className="tg-73a0" colSpan={4}>
            <span style={{ fontWeight: "bold" }}>To/Consignee.</span>
            <br />
            {dcdetails ? dcdetails.clientname : null} <br/>
            {dcdetails ? dcdetails.clientaddress : null}
            <br />
            <span style={{ fontWeight: "bold" }}>GST No. {dcdetails ? dcdetails.clientgst : null}</span>
          </td>
          <td className="tg-73a0" colSpan={3}>
            <span style={{ fontWeight: "bold" }}>
              From/Consignor. AD Pharma Equipment Pvt.Ltd.{" "}
            </span>
            <br />
            Redg./Co.op Office:Shop No-05 Dream Art, Modern Colony, Saradwadi
            Road, Tal Sinnar, Dist Nashik, Maharashtra, India 422103.<br/> +91
            9673905522. +91 9673915522. <br />
            Email : sales@adpharmaequipment.com &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; website :
            www.adpharmaequipment.com
          </td>
        </tr>
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Sr.No.</span>
          </td>
          <td className="tg-0pky" colSpan={4}>
            <span style={{ fontWeight: "bold" }}>Material Desc / ID No.</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Qty</span>
          </td>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Pkt/Wt</span>
          </td>
        </tr>
        {dcproducts ? dcproducts.map( data => {
           
           const Data1Array = GetproductsSnoDescription ? GetproductsSnoDescription.filter(data1 => { 
            return data1.ProductID === data.productid 
           })  :null;

            // GetUnitList
            const Data2Array = GetUnitList ? GetUnitList.filter(data1 => { 
              return data1.UnitID === data.unit 
             })  :null;
             allquantity = allquantity+parseInt(data.quantity);
             pktwt = pktwt+parseInt(data.pkg);
            return <tr>
            <td className="tg-0pky">{a++}</td>
            <td className="tg-0pky" colSpan={4}>
              <span style={{ fontWeight: "bold" }}>{data.productname}</span>
              <br />
             {/* product Desc here */}
             {Data1Array ? Data1Array[0] ? Data1Array[0].Description : null : null}
             
            </td>
            <td className="tg-0pky">{data.quantity}{Data2Array ? Data2Array[0] ? Data2Array[0].Abbreviation : null : null}</td>
            <td className="tg-0pky">{data.pkg}</td>
          </tr>
            
        }) : null}
        
        {/* <tr>
          <td className="tg-0pky">2</td>
          <td className="tg-0pky" colSpan={4}>
            <span style={{ fontWeight: "bold" }}>3.5 Drill Bit</span>
            <br />
            3.5 Drill Bit
          </td>
          <td className="tg-0pky">1</td>
          <td className="tg-0pky">12</td>
        </tr>
        <tr>
          <td className="tg-0pky">3</td>
          <td className="tg-0pky" colSpan={4}>
            <span style={{ fontWeight: "bold" }}>RIBET GUN</span>
            <br />
            Ribit gun
          </td>
          <td className="tg-0pky">1</td>
          <td className="tg-0pky">4</td>
        </tr> */}
        <tr>
          <td className="tg-0pky">
            <span style={{ fontWeight: "bold" }}>Total</span>
          </td>
          <td className="tg-0pky" colSpan={4} />
          <td className="tg-0pky">{allquantity}</td>
          <td className="tg-0pky">{pktwt}</td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            <span style={{ fontWeight: "bold" }}>Remark : </span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            Returnable Within No of Days : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Initiating Dept :
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            Purpose for Sent :
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            <span style={{ fontWeight: "bold" }}>
              Transporter's Courier Details :
            </span>
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            Transporter :
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            Vehicle No:
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            LR No. : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LR. Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Docket No.:
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7}>
            <span style={{ fontWeight: "bold" }}>Consignor GSTIN : &nbsp;27AAPCA3611H1ZQ</span>
          </td>
        </tr>
        <tr>
          <td className="tg-0lax" colSpan={7}>
            Amount in Word :{" "}
          </td>
        </tr>
        <tr>
          <td className="tg-0pky" colSpan={7} rowSpan={2}>
            Generated By : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Received By :<br />
            {userdata ? userdata.name : null}
            <br />
            {dcdetails ? dcdetails.createdate : null}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={stamp} height={80} width={80} alt="adstamp" />
            {/* <br />
            <br /> */}
            <br />
            For AD Pharma Equipment Pvt. Ltd. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seal &amp; Sign
          </td>
        </tr>
        <tr></tr>
      </tbody>
    </table>
  </div>
</div>
<button onClick={()=>{generatePDF()}}>Print</button>
 </div>


    </>
}

export default ToPdfViewerSer;