import { useEffect, useState } from "react";
import Dashboard from "../Dashboard";
import axios from "axios";
import ServerUrl from "../ServerUrl";
import ProductIn from "./ProductIn";
import "./Products.css";
import ProDef from "./ProDef";
import ProductOut from "./ProductOut";
import JobNo from "./JobNo";
const Products = () => { 
    const [userdata, setuserdata] = useState([]);
    useEffect(() => {
        axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
        //  console.log(res)
          if(res.data.flag === true){
            //   window.location.href = "/dashboard";
            setuserdata(res.data.data);
          }else if(res.data.flag === false){
            window.location.href = "/";
          }
        }
        ).catch((err)=>{
          console.log(err);
        });
    
      }, []);
      
      const [modulesUp, setmodulesUp] = useState(<ProDef  />);
    return (
        <>
           <Dashboard />
{
    userdata ? userdata.role === "2" || userdata.role === "1" ?
    <>
    {/* Main */}

    
    <>
  <div className="sidebar">
    <a onClick={()=> { setmodulesUp(<ProDef />) }} href="#add">
      Products List
    </a>
    <a onClick={()=> { setmodulesUp(<ProductIn />) }} href="#in">Products In</a>
    <a onClick={()=> { setmodulesUp(<ProductOut />) }}>ProductOut</a>
    <a onClick={()=> { setmodulesUp(<JobNo />) }} href="#job">Job History</a>
  </div>
  <div className="content">
        {/* COntent Here */}
        {modulesUp}
  </div>
</>












    </>:
    <>
    {/* Blank */}
    </>
    :<>
    {/* Blank */}
    </>
}


        </>
    )
}
export default Products;