import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import ServerUrl from "./ServerUrl";
function App() {
  const [useremail, setuseremail] = useState();
  const [userpassword, setuserpassword] = useState();
  useEffect(() => {
    axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
     console.log(res)
      if(res.data.flag === true){
          window.location.href = "/dashboard";
      }else if(res.data.flag === false){
        // window.location.href = "/";
      }
    }
    ).catch((err)=>{
      console.log(err);
    });

  }, []);
  const HandleSubmitForLogin = (e) => { 
    e.preventDefault();
    if(Boolean(useremail) && Boolean(userpassword)){
          const formdata = new FormData();
          formdata.append("email",useremail);
          formdata.append("password",userpassword);
          axios.post(ServerUrl+"public/loginuser.php", formdata, 
            { withCredentials : true }
          ).then((res)=>{
            if(res.data.flag === true){
              console.log(res)
                window.location.href = "/dashboard";
            }else{
              alert(res.data.message);
            }
          }
          ).catch((err)=>{
            console.log(err);
          });

    }else{  
      alert("Please Enter Email and Password");
    }
  }
  return (
    <div className="body-login">

<div className="container-login">
  <h1 className="title">AD Pharma Equipment Pvt. Ltd.</h1>
  <div className="card">
    <form className="form-login" onSubmit={HandleSubmitForLogin}>
      <strong style={{"color":"white"}}>Store Managment</strong>
      <input className="input-login" type="text" onChange={(e) => { 
        setuseremail(e.target.value);
      }} placeholder="Username" />
      <input className="input-login" type="password" onChange={(e) => { 
        setuserpassword(e.target.value);
      }} placeholder="Password" />
      <div className="buttons">
        {/* <a href="#" className="register-link">
          Register
        </a> */}
        <button type="submit" className="login-button">
          Login
        </button>
      </div>
    </form>
  </div>
</div>

    </div>
  );
}

export default App;
