import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ServerUrl from "../ServerUrl";
import Select from "react-select";

const ProductOut = () => {
  // Ref Variable For All Fields
  const jobnodetails = useRef();
  const [GetProductDetails, SetProductDetails] = useState();
  const quantity = useRef();
  const unit = useRef();
  const productdetails = useRef();
  const [jobnoList, setJobNoList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/JobNo/getjobnolistwithone.php", {
        withCredentials: true,
      })
      .then((res) => {
        setJobNoList(res.data);
        // console.log(vendorsList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const OptionToOutProduct = jobnoList.map((data) => {
    return { label: data.Name + " Job No : " + data.JobNo, value : data.JobNo };
  });

  const [productsList, setProductsList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/getproductslist.php", {
        withCredentials: true,
      })
      .then((res) => {
        setProductsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const productsoptions = productsList.map((item) => {
    return { value: item.ProductID, label: item.Name+' '+item.Description };
  });

  // Units List
  const [unitsList, setUnitsList] = useState([]);
  useEffect(() => {
    axios
      .get(ServerUrl + "public/products/productin/getunits.php", {
        withCredentials: true,
      })
      .then((res) => {
        setUnitsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const unitsoptions = unitsList ? unitsList.map((item) => {
    return { value: item.UnitID, label: item.Abbreviation };
  }) : null;
// Main Logical Quantity of Products To Out
const [GetStackProductQuant, SetStackProductQuant] = useState([]);
useEffect(()=>{
  axios
      .get(ServerUrl + "public/products/MfgStackApi/StockAvailable.php", {
        withCredentials: true,
      })
      .then((res) => {
        SetStackProductQuant(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
},[]);
// console.log(GetStackProductQuant)
const productStackArray = GetStackProductQuant.filter((item) => {
  return parseInt(item.FinalStock) !== 0 && parseInt(item.FinalStock) > 0
});

const productStackOptions = productStackArray.map(item => { 
  
  return { value: item.ProductID, label: item.Name+' '+item.Description , value2:item.FinalStock};
})



const HandlePostInsertData = (e)  =>{
        e.preventDefault()
        if(Boolean(jobnodetails.current.props.value) && Boolean(GetProductDetails.value) && Boolean(quantity.current.value) && Boolean(unit.current.props.value)){
                const formdata = new FormData();
                /*
                    $productname= $_POST['productname'];
                    $productid = $_POST['productid'];
                    $clientid = $_POST['clientid'];
                    $clientname = $_POST['clientname'];
                    $quantity = $_POST['quantity'];
                    $unitid = $_POST['unitid'];
                */
                formdata.append("productname", GetProductDetails.label); 
                formdata.append("productid", GetProductDetails.value);
                formdata.append("clientid", jobnodetails.current.props.value.value);
                formdata.append("clientname", jobnodetails.current.props.value.label);
                formdata.append("quantity", quantity.current.value);
                formdata.append("unitid", unit.current.props.value.value);
                axios.post(ServerUrl+"public/products/productout/addproductout.php", formdata, { withCredentials : true }).then(response => {
                        if(response.data.flag === true){
                            alert("Product Out Created Successfully");
                            quantity.current.value = "";
                            jobnodetails.current.clearValue();
                            productdetails.current.clearValue();
                            unit.current.clearValue();
                            window.location.reload();
                        }else{
                            alert("Something Went Wrong while Product Out");
                        }
                }).catch(err => {
                    console.log(err)
                })

        }else {
            alert("All Fields Required")
        }

}


const [listproductout, setProductsOutList] = useState([]);
useEffect(()=>{
  axios.get(ServerUrl+"public/products/productout/getoutproduct.php", {withCredentials : true}).then(res => {
    setProductsOutList(res.data);
  }).catch(err =>{
    console.log(err);
  })
})

const [searchvariable, setSearchvariable] = useState("");
const ArrayForFIltered = listproductout.filter((product) => {
  // I want to Search with All Fields
  return product.ProductName.toLowerCase().includes(searchvariable.toLowerCase()) || product.ClientName.toLowerCase().includes(searchvariable.toLowerCase()) || product.Quantity.toLowerCase().includes(searchvariable.toLowerCase()) || product.CreateDate.toLowerCase().includes(searchvariable.toLowerCase());
}
)

  return (
    <>
    <form onSubmit={HandlePostInsertData}>
      <h1>Product Out</h1>
      <hr />
      <label>Job No</label>
      <Select options={OptionToOutProduct} ref={jobnodetails} placeholder="Select Job No" />
      <br />
      <label>Select Product To Out</label>
      <Select options={productStackOptions} onChange={(newValue)=>{ SetProductDetails(newValue) }}  placeholder="Select Product to Out" />
      <br />
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <label>Enter Quantity</label>
            <input
              type="number"
              placeholder={"Enter Quanity"}
              className="form form-control"
              max={GetProductDetails ? GetProductDetails.value2 : 0}
              min={0}
              onChange={(e) => {
                isNaN(e.target.value)
                  ? (e.target.style.color = "red")
                  : (e.target.style.color = "black");
              }}
              ref={quantity}
            />
          </div>

          <div className="col-sm">
            <label> Select Unit</label>
            <Select
              options={unitsoptions}
              ref={unit}
              placeholder="select Unit"
            />
          </div>
        </div>
      </div>


      <hr/>
      <button type="submit" className="btn btn-primary" >Submit</button>&nbsp;<button type="reset" className="btn btn-danger">Clear</button>
      </form>

              <hr/>
              {/* Search In table */}
<input type="text" className="form form-control" onChange={(e) => {
     setSearchvariable(e.target.value);
 }} placeholder="Search In Products" />
              <div className="table-container">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>SNo</th>
              <th>Product Name</th>
              <th>Client Name</th>
              <th>Quantity</th>
              <th>Claim Status</th>
              <th>Create Date</th>
            </tr>
          </thead>
          <tbody>
                  {ArrayForFIltered ? ArrayForFIltered.map(data => {
                    return <tr>
                      <td>{data.SNo}</td>
                      <td>{data.ProductName}</td>
                      <td>{data.ClientName}</td>
                      <td>{data.Quantity}</td>
                      <td>{data.ClientID === "1" ? data.claimstatus === "1"? "Claimed" : "Not Claimed" : "NA"}</td>
                      <td>{data.CreateDate}</td>
                    </tr>
                  }).reverse() : <></>}
          </tbody>
              </table>
              </div>

    </>
  );
};

export default ProductOut;
