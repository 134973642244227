import axios from "axios";
import { useEffect, useState } from "react";
import ServerUrl from "./ServerUrl";
import { Link } from "react-router-dom";

const Dashboard = () => {
    const [userdata, setuserdata] = useState();
    
  useEffect(() => {
    axios.get(ServerUrl+"public/checkvalidtoken.php", { withCredentials : true }).then((res)=>{
    //  console.log(res)
      if(res.data.flag === true){
        //   window.location.href = "/dashboard";
        setuserdata(res.data.data);
        
      }else if(res.data.flag === false){
        window.location.href = "/";
      }
    }
    ).catch((err)=>{
      console.log(err);
    });

  }, []);

    return(<>
    
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <Link className="navbar-brand" to='/dashboard'>
      <strong><font color="purple">ADPEPL</font> <font color="blue">Store</font></strong>
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/dashboard">
            Home
          </Link>
        </li>

        {userdata ? userdata.role === "2" || userdata.role === "1" ? 
        <li className="nav-item">
          <Link className="nav-link" to="/products">
            Products
          </Link>
        </li> : <>
          <Link className="nav-link" to="/sproducts">
            Products
          </Link>
        </> : <></>}
        {/* <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </li> */}
  {userdata ? userdata.role === "3" || userdata.role === "1" || userdata.role === "2" ?  <li className="nav-item">
          <Link
            className="nav-link"
            to="/dc"
            onClick={localStorage.setItem("urole", userdata.role)}
          >
            Delivery Challan
          </Link>
        </li>

        :<></>:<></>}
        {userdata ?  userdata.role === "2" ?  <>  <li className="nav-item">
          <Link
            className="nav-link"
            to="/dcr"
          >
            Return Stock Manfacturing
          </Link>
        </li></> : <>
        <Link
            className="nav-link"
            to="/serdcr"
          >
            Return Stock Service
          </Link>
        </> : <></>}

        {userdata ?  userdata.role === "1" ?  <>  <li className="nav-item">
          <Link
            className="nav-link"
            to="/dcr"
          >
            Return Stock Manfacturing
          </Link>
        </li>
        </> : <></> : <></>}

        {userdata ? userdata.role === "1" ?  <>  <li className="nav-item">
          <Link
            className="nav-link"
            to="/settings"
          >
            Settings
          </Link>
        </li></> : <></> : <></>}
      </ul>
      <form className="d-flex">
        {/* <input
          className="form-control me-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
        <button className="btn btn-outline-success" type="submit">
          Search
        </button> */}

<button className="btn btn-danger" onClick={()=> { 
  axios.get(ServerUrl+"public/deletehttptokencoockie.php", {withCredentials:true}).then(res=> { 
    window.location.reload();
  }).catch(err => { 
    console.log(err);
  })
}}>
          Logout
        </button>
      </form>
    </div>
  </div>
</nav>

    
    
    </>);
}
export default Dashboard;